import {
    BrowserRouter,
    Routes,
    Route,
    Navigate
} from "react-router-dom";
import {Dashboard} from "./components/dashboard";
import {UserContext} from "./providers/UserProvider";
import Offline from "./components/Offline";
import {useContext} from "react";
import ProviderRedirect from "./components/onboarding/ProviderRedirect";


export default function Router() {
    const {online} = useContext(UserContext);


    if(!online)
        return (
        <Offline/>
        )

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/dashboard/*" element={<Dashboard/>}/>
                <Route path="/git/provider/:provider/redirect" element={<ProviderRedirect/>}/>
                <Route path="/*" element={<Navigate to="/dashboard/proactive"/>}/>
            </Routes>
        </BrowserRouter>
    );
}