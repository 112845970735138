import {createContext, useEffect, useState, useCallback, useContext} from 'react';
import {UserContext} from "./UserProvider";

export const TicketContex = createContext([]);


export default function TicketProvider({children}) {
    const [tickets, setTickets] = useState([])
    const {backendService} = useContext(UserContext);


    const fetchData = useCallback(async () => {
        const tickets = await backendService.getTickets()
        setTickets(tickets);
    }, [backendService]);


    useEffect(() => {
        fetchData();
    }, [fetchData]);


    return (
        <TicketContex.Provider
            value={{tickets}}>
            {children}
        </TicketContex.Provider>
    )
}
