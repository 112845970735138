import {createContext, useEffect, useState, useCallback, useContext} from 'react';
import {UserContext} from "./UserProvider";

export const FeatureContex = createContext([]);


export default function FeatureProvider({children}) {
    const [features, setFeatures] = useState([])
    const {backendService} = useContext(UserContext);


    const fetchData = useCallback(async () => {
        const features = await backendService.getFeatures()
        setFeatures(features);
    }, [backendService]);


    useEffect(() => {
        fetchData();
    }, [fetchData]);


    return (
        <FeatureContex.Provider
            value={{features}}>
            {children}
        </FeatureContex.Provider>
    )
}
